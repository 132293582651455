import React from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaYoutube, FaDiscord } from 'react-icons/fa';
const Footer = () => (
  <footer className="bg-gradient-to-b from-slate-900 to-black text-gray-400 py-8">
    <div className="container mx-auto px-4 text-center">
      <hr className="border-gray-700 mb-2" />
      <motion.p
        className="text-lg mb-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        © {new Date().getFullYear()} NotReal003. All rights reserved.
      </motion.p>
      <motion.div
        className="flex justify-center gap-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <a
          href="https://github.com/NotReal003"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white transition duration-300 ease-in-out hover:scale-110"
        >
          <FaGithub size={30} />
        </a>
        <a
          href="https://youtube.com/@NotNT77?si=j7kgkkCkrha0aNNc"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white transition duration-300 ease-in-out hover:scale-110"
        >
          <FaYoutube size={30} />
        </a>
        <a
          href="https://discord.gg/sqVBrMVQmp"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white transition duration-300 ease-in-out hover:scale-110"
        >
          <FaDiscord size={30} />
        </a>
      </motion.div>
    </div>
  </footer>
);

export default Footer;