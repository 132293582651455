import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const navigation = [
  { name: 'Requests', href: 'https://request.notreal003.xyz', external: true },
  { name: 'Patreon', href: 'https://www.patreon.com/NotNT77', external: true },
  { name: 'GitHub', href: 'https://github.com/notreal003', external: true },
  { name: 'Players', href: '/player', external: false },
  { name: 'Support', href: '/support', external: false },
  { name: 'SkyLine Guild', href: 'https://request.notreal003.xyz/apply', external: true },
];

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Popover className="sticky top-0 z-50 bg-gradient-to-b from-slate-900 to-black">  {/* Sticky navbar */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <p onClick={() => navigate('/')}>
              <span className="sr-only">NotNT | NotReal</span>
              <span className="text-white text-lg font-bold">NotNT | NotReal</span>
            </p>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex space-x-10"> {/* Removed Popover.Group */}
            {navigation.map((item) => (
              <Fragment key={item.name}>
                {item.external ? (
                  <a href={item.href} className="text-base font-medium text-gray-300 hover:text-white">
                    {item.name}
                  </a>
                ) : (
                  <Link to={item.href} className="text-base font-medium text-gray-300 hover:text-white">
                    {item.name}
                  </Link>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"> {/* Fixed positioning */}
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-zinc-950/90 backdrop-blur-xs shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"> {/* Overflow hidden */}
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-white text-lg font-bold">NotNT | NotReal</span>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-zinc-950 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 space-y-6"> {/* Added spacing */}
                {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-700"
                      target={item.external ? "_blank" : undefined}
                       rel={item.external ? "noopener noreferrer": undefined} // Added rel for external links
                    >
                      <span className="text-base font-medium text-gray-300 hover:text-white">
                        {item.name}
                      </span>
                    </a>
                  ))}

              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Navbar;