import React, { useState } from "react";
import axios from "axios";
import { ImSpinner, ImHammer2 } from "react-icons/im";
import { RiSendPlaneFill } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import { ExternalLink } from "lucide-react";

const Player = () => {
  const [playerName, setPlayerName] = useState("");
  const [playerData, setPlayerData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [punishments, setPunishments] = useState([]);
  const [punishmentsLoading, setPunishmentsLoading] = useState(false);
  const [punishmentsFetched, setPunishmentsFetched] = useState(false);
  const [punishmentsError, setPunishmentsError] = useState("");
  const [selectedPunishment, setSelectedPunishment] = useState(null);
  const [showAltPunishments, setShowAltPunishments] = useState(false);
  const [usernameHistoryExpanded, setUsernameHistoryExpanded] = useState(false);

  const TransferXuid = async (xuid) => {
    window.location.href = `https://portal.nethergames.org/player/${xuid}`;
  };
  const searchPlayer = async () => {
    setLoading(true);
    setError("");
    setPlayerData(null);
    setPunishments([]);
    setPunishmentsFetched(false);
    setShowAltPunishments(false);

    try {
      const response = await fetch(
        `https://api.ngmc.co/v1/players/${playerName}?period=global&withReportCount=true&withUsernameHistory=true&withWinStreaks=true`,
      );
      if (!response.ok) {
        throw new Error(`${response.status}: Cannot find ${playerName}`);
      }

      const data = await response.json();

      if (!data || !data.name) {
        throw new Error("Invalid data structure received.");
      }

      setPlayerData(data);
      sendToDiscordWebhook(data.name);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPunishments = async () => {
    setPunishmentsLoading(true);
    setPunishmentsError("");

    try {
      const response = await axios.get(
        `https://api.ngmc.co/v1/players/${playerData.xuid}/punishments`,
      );
      setPunishments(response.data.punishments);
      if (response.data.punishments.length === 0) {
        toast.success("No punishments found for this player :)");
      }
      setPunishmentsFetched(true);
    } catch (error) {
      setPunishmentsError("Failed to fetch punishments.");
    } finally {
      setPunishmentsLoading(false);
    }
  };

  const sendToDiscordWebhook = (playerName) => {
    const webhookURL =
      "https://discord.com/api/webhooks/1213847581889663018/FJu4a86d_-CHTwW2ylL3g8ZlUaurUjXLLxIh25_6w8fysQkLR785n6xdCHpw9dmaM1WW";
    const payload = {
      content: `Player ${playerName} searched successfully!`,
    };

    fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to send message to Discord webhook. Status: ${response.status}`,
          );
        }
      })
      .catch((error) =>
        console.error("Error sending message to Discord webhook:", error),
      );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchPlayer();
    }
  };

  const getBackgroundColor = (label) => {
    switch (label.toLowerCase()) {
      case "admin":
        return "#D6342A";
      case "titan":
        return "#B91C1D";
      case "ultra":
        return "#DD9833"; // Hex color for 'gold'
      case "emerald":
        return "#40BA4B";
      case "legend":
        return "#02D3F1";
      case "crew":
        return "#86F103";
      case "trainee":
        return "#F1C40F";
      case "banned":
        return "E53E3E";
      case "muted":
        return "#F8E15F";
      case "tester":
        return "#01AA01";
      case "mod":
        return "#3497DA"; // Hex color for 'cyan'
      case "media":
        return "#127F6A"; // Hex color for 'gold'
      case "supervisor":
        return "#E258C0"; // Hex color for 'gold'
      case "game designer":
        return "#BF7B10"; // Hex color for 'gold'
      case "partner":
        return "#DF6A00"; // Hex color for 'gold'
      default:
        return "bg-zinc-400"; // Default background color
    }
  };

  const getInlineStyle = (label) => {
    const backgroundColor = getBackgroundColor(label);
    return backgroundColor.startsWith("#") ? { backgroundColor } : {};
  };

  const processMarkdownSyntax = (text) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/__(.*?)__/g, "<em>$1</em>")
      .replace(
        /\[(.*?)\]\((.*?)\)/g,
        '<a href="$2" target="_blank" style="color: orange;">$1</a>',
      )
      .replace(/\n/g, "<br/>");
  };

  const handlePunishmentClick = (punishment) => {
    setSelectedPunishment(punishment);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gradient-to-b from-slate-900 to-black text-white p-4">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">Search Player</h2>
        <div className="flex justify-center mb-4">
          <input
            type="text"
            className="bg-black border border-gray-700 rounded-md px-4 py-2 w-full font-bold max-w-md text-white focus:outline-none focus:border-blue-500 transition-colors duration-200"
            placeholder="Enter player name"
            value={playerName}
            minLength="1"
            maxLength="16"
            required
            onChange={(e) => setPlayerName(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            className={`ml-2 px-4 py-2 rounded-md font-semibold transition-colors duration-200 ${
              loading
                ? "bg-gray-700 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            }`}
            onClick={searchPlayer}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                Search <ImSpinner className="animate-spin ml-2" />
              </span>
            ) : (
              <span className="flex items-center justify-center">
                Search <RiSendPlaneFill className="ml-2" />
              </span>
            )}
          </button>
        </div>
        {error && (
          <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded-md text-center mb-4">
            {error}
          </div>
        )}
        {playerData && (
          <div className="bg-gray-800 rounded-lg shadow-lg mt-4 overflow-x-auto">
            <div className="p-6">
              <div className="flex flex-col items-center mb-6">
                <img
                  src={playerData.avatar}
                  alt={`${playerData.name}'s avatar`}
                  className="rounded-full mb-4 border-2 border-indigo-500 w-28 h-28"
                />
                <h1 className="text-4xl font-bold mb-2">{playerData.name}</h1>
                <div className="flex flex-wrap justify-center mb-4">
                  {playerData.ranks &&
                    (Array.isArray(playerData.ranks)
                      ? playerData.ranks
                      : playerData.ranks.split(",")
                    ).map((rank, index) => (
                      <span
                        key={index}
                        className={`text-white px-2 font-bold text-xs shadow-black rounded-full m-1 ${
                          getBackgroundColor(rank).startsWith("bg-")
                            ? getBackgroundColor(rank)
                            : ""
                        }`}
                        style={{
                          ...getInlineStyle(rank),
                          textShadow: "1px 1px 2px black",
                        }}
                      >
                        {rank.toUpperCase()}
                      </span>
                    ))}
                  {playerData.banned && (
                    <span className="bg-red-500 text-xs shadow-black text-white px-2 rounded-full font-bold m-1">
                      BANNED
                    </span>
                  )}
                  {playerData.muted && (
                    <span className="bg-yellow-500 text-xs shadow-black text-black px-2 rounded-full font-bold m-1">
                      MUTED
                    </span>
                  )}
                </div>
              </div>
              <hr className="border-gray-700 mb-2" />

              <div
                className="opacity-0 transition-opacity duration-500"
                style={{ opacity: playerData.bio ? 1 : 0 }}
              >
                <p className="mb-2 overflow-x-auto prose prose-invert">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: processMarkdownSyntax(playerData.bio || ""),
                    }}
                  />
                </p>
              </div>

              <hr className="border-gray-700 mb-2" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="mb-2">
                    <strong>Level:</strong> {playerData.level || 0}
                  </p>
                  <p className="mb-2">
                    <strong>Discord:</strong> {playerData.discordTag || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>Guild:</strong> {playerData.guild || "N/A"}
                  </p>
                  <p className="mb-2">
                    <strong>Tier:</strong> {playerData.tier || "N/A"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Wins:</strong> {playerData.wins || 0}
                  </p>
                  <p className="mb-2">
                    <strong>Losses:</strong> {playerData.losses || 0}
                  </p>
                  <p className="mb-2">
                    <strong>Kills:</strong> {playerData.kills || 0}
                  </p>
                  <p className="mb-2">
                    <strong>Deaths:</strong> {playerData.deaths || 0}
                  </p>
                  <p className="mb-2">
                    <strong>Reports Submitted:</strong>{" "}
                    <span className="font-bold text-red-600 ml-1">
                      {playerData.reportCount || 0}
                    </span>
                  </p>
                </div>
              </div>

              {playerData.usernameHistory &&
                Array.isArray(playerData.usernameHistory) &&
                playerData.usernameHistory.length > 0 && (
                  <div>
                    <h4 className="text-md font-bold">Previously Known As</h4>
                    <ul className="list-disc list-inside">
                      {playerData.usernameHistory.map((name, index) => (
                        <li key={index} className="text-gray-300 hover:text-white hover:underline">
                          {name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              {punishmentsFetched && (
                <div className="mt-6">
                  <h4 className="text-lg font-bold mb-2">Punishments</h4>
                  {punishments.length === 0 && (
                    <p className="text-indigo-500 font-bold">
                      No punishments found for this player.
                    </p>
                  )}
                  <hr className="border-gray-700 mb-2" />
                  {selectedPunishment && punishments.length !== 0 && (
                    <div className="mt-4">
                      <p className="text-lg font-bold mb-2">
                        Punishment Details ({selectedPunishment.type})
                      </p>
                      <p className="text-gray-400">
                        REASON: <span className="font-bold text-orange-600">{selectedPunishment.reason}</span>
                      </p>
                      <p className="text-gray-400">
                        ID: <span className="font-bold text-white">{selectedPunishment.id}</span>
                      </p>
                      <p className="text-gray-400">
                        CREATED AT:{" "}
                        <span className="font-bold text-white">{new Date(
                          selectedPunishment.issuedAt * 1000,
                        ).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                          timeZoneName: "short",
                        })}</span>{" "}
                      </p>
                      <p className="text-white font-bold">
                        <span className="text-gray-400 font-normal">VALID UNTIL:</span>{" "}
                        {selectedPunishment.validUntil === 0 ? (
                          <span className="font-bold text-red-500">Permanent</span>
                        ) : (
                      
                          new Date(selectedPunishment.validUntil * 1000).toLocaleString("en-US", {
                            timeZone: "Asia/Kolkata",
                            timeZoneName: "short",
                          })
                        )}
                      </p>

                      <p className="text-indigo-500">
                        ACTIVE:{" "}
                        {selectedPunishment.active ? (
                          <span className="font-bold text-red-500">Yes</span>
                        ) : (
                          <span className="font-bold>">No</span>
                        )}
                      </p>

                      <p className="text-gray-400">
                        ALT PUNISHMENT: {selectedPunishment.alt ? <span className="font-bold text-red-500">Yes</span> : <span className="font-bold text-indigo-500">No</span>}
                      </p>
                      <p className="text-gray-400">
                        Affected Players:{" "}
                      <span
                        onClick={() => TransferXuid(selectedPunishment.affectedXuids[0])}
                        className="text-red-500 font-bold hover:underline"
                      >
                        {selectedPunishment.affectedPlayers.join(", ")} <ExternalLink />
                      </span>
</p>
                    </div>
                  )}
                  <hr className="border-gray-700 mt-2 mb-2" />
                  {punishments.length > 0 && (
                    <ul className="list-disc list-inside space-y-1">
                      {punishments.map((punishment, index) => (
                        <li
                          key={index}
                          className="text-red-500 font-bold cursor-pointer hover:underline overflow-x-auto"
                          onClick={() => handlePunishmentClick(punishment)}
                        >
                          {punishment.reason} {punishment.alt && <span className="text-indigo-500 font-bold"> - (Alt)</span>}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {punishmentsLoading && (
                <div className="mt-6">
                  <p className="text-gray-400">Finding punishments...</p>
                  <div className="flex items-center justify-center mt-2">
                    <ImSpinner className="animate-spin ml-2" />
                  </div>
                </div>
              )}
              {punishmentsError && (
                <div className="mt-6">
                  <p className="text-red-500">{punishmentsError}</p>
                </div>
              )}
              {!punishmentsFetched && playerData && (
                <div className="mt-6">
                  <button
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={fetchPunishments}
                  >
                    Punishments
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Player;
